export enum MaterialType {
  PowerCard = 1,
  MainBoard,
  MonsterBoard,
  HealthCounter,
  Pawn,
  Buzz,
  Dice,
  Energy,
  DiceToken
}
